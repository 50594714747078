import { anyPass, complement, equals, isEmpty, isNil } from 'rambdax';

import { Empty, Nil, NilOrEmpty, Nullable } from './types';

export const slugify = (str?: string) =>
  str
    ?.replace(/[^ \w]/g, '')
    .replace(/[\W]/g, '-')
    .toLowerCase();

export const isNull = equals(null) as <T>(x: Nullable<T>) => x is null;
export const isNotNull = complement(isNull) as <T>(x: T) => x is Exclude<T, null>;
export const isNilOrEmpty = anyPass([isNil, isEmpty]) as <T>(x: T | NilOrEmpty) => x is NilOrEmpty;
export const isNotNil = complement(isNil) as <T>(x: T) => x is Exclude<T, Nil>;
export const isNotEmpty = complement(isEmpty) as <T>(x: T) => x is Exclude<T, Empty>;
export const isNotNilOrEmpty = complement(isNilOrEmpty) as <T>(x: T) => x is Exclude<T, NilOrEmpty>;
