import { AuthBindings } from '@refinedev/core';
import { AuthHelper } from '@refinedev/strapi-v4';
import axios from 'axios';

import { STRAPI_HOST, STRAPI_JWT_KEY } from './constants';

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(`${STRAPI_HOST}/api`);

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    const { data, status } = await strapiAuthHelper.login(email, password);
    if (status === 200) {
      localStorage.setItem(STRAPI_JWT_KEY, data.jwt);

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.jwt}`;

      return {
        success: true,
        redirectTo: '/'
      };
    }
    return {
      success: false,
      error: new Error('Invalid username or password')
    };
  },
  logout: async () => {
    localStorage.removeItem(STRAPI_JWT_KEY);
    return {
      success: true,
      redirectTo: '/login'
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async () => {
    const token = localStorage.getItem(STRAPI_JWT_KEY);
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return {
        authenticated: true
      };
    }

    return {
      authenticated: false,
      error: new Error('Not authenticated'),
      logout: true,
      redirectTo: '/login'
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(STRAPI_JWT_KEY);
    if (!token) {
      return null;
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const { id, username, email } = data;
      return {
        id,
        name: username,
        email
      };
    }

    return null;
  }
};
